let info = {
  config: {
    use_cookies: true,
    navbar: {
      blur: false
    }
  },

  name: "Chen Peng",
  title: "LPC, LMHC",

  logo_picture: require("./src/assets/logo/asa_logo_final.png"),
  flat_picture: require("./src/assets/photos/pengchen_v1.jpg"),

  links: {
    linkedin: "",
    psychology_today: "https://www.psychologytoday.com/us/therapists/chen-peng-jersey-city-nj/1316958",
  },
  
  icons: {
    anxiety: {
      dark: require("./src/assets/icons/anxiety_dark.svg"),
      light: require("./src/assets/icons/anxiety_light.svg")
    },
    acculturation: {
      dark: require("./src/assets/icons/acculturation_dark.svg"),
      light: require("./src/assets/icons/acculturation_light.svg")
    },
    depression: {
      dark: require("./src/assets/icons/depression_dark.svg"),
      light: require("./src/assets/icons/depression_light.svg")
    },
    relationship: {
      dark: require("./src/assets/icons/relationship_dark.svg"),
      light: require("./src/assets/icons/relationship_light.svg")
    },
    selfesteem: {
      dark: require("./src/assets/icons/selfesteem_dark.svg"),
      light: require("./src/assets/icons/selfesteem_light.svg")
    },
    trauma: {
      dark: require("./src/assets/icons/trauma_dark.svg"),
      light: require("./src/assets/icons/trauma_light.svg")
    },
  },

  insurances: [
    {
      title: "lyra",
      picture_dark: require("./src/assets/insurances/lyra_dark.svg"),
      picture_light: require("./src/assets/insurances/lyra_light.svg")
    },
    {
      title: "united",
      picture_dark: require("./src/assets/insurances/united_dark.svg"),
      picture_light: require("./src/assets/insurances/united_light.svg")
    },
    {
      title: "blue cross",
      picture_dark: require("./src/assets/insurances/blue-cross_dark.svg"),
      picture_light: require("./src/assets/insurances/blue-cross_light.svg")
    },
    {
      title: "aetna",
      picture_dark: require("./src/assets/insurances/aetna_dark.svg"),
      picture_light: require("./src/assets/insurances/aetna_light.svg")
    },
    {
      title: "anthem",
      picture_dark: require("./src/assets/insurances/anthem_dark.svg"),
      picture_light: require("./src/assets/insurances/anthem_light.svg")
    },
    {
      title: "and more..."
    },
  ],
};

export default info;
